<template>
  <router-view class="mx-0 px-0" :key="$route.path"/> 
</template>
<script>
  export default {
    name: 'void',
    created: function () {
    },
  }
</script>
<style scoped>
</style>
