import Vue from 'vue'
import VueRouter from 'vue-router'


import store from '../store'

//import * as jwtDecode  from 'jwt-decode'
import jwt_decode from "jwt-decode";

import CMS from "@/views/CMS.vue"
import VOID from "@/views/void.vue"



Vue.use(VueRouter)

async function redirectIfNotAuth (to, from, next) {
 
  let r=""
  let decode
  let token = localStorage.getItem('token')

  try {
    decode = await jwt_decode(token) 

    if (token && decode && decode.login){
      if (store.state._session.login){
        localStorage.setItem('route', to.path)
      }
      else {
        store.state._session={
          login: decode.login,
          modulo: decode.modulo,
          redir: decode.redir,
          token,
          userID: decode.userID,
          userName: decode.userName,
          userType: decode.userType,
          usr: decode.usr
        }

        if (window.innerWidth>1264) {store.state.menuPpal.drawer=true}
        next(localStorage.getItem('route'))
      }   
      next()
    }
    else {
      console.log("limpiar state")
      localStorage.clear()
      store.state._session=undefined

      if (to.path!="/") {
        next("/")
      } else {next()}
    }
  }
  catch (err) {
    console.log(err)
    console.log("error")
    if (to.path!="/") {
      next("/")
    } else {next()}
  }
  
}


function getState (state) {
  return new Promise((resolve, reject) => {
    if (store.state[state] === undefined) {
      const unwatch = store.watch(
        () => store.state[state],
        (value) => {
          unwatch()
          resolve(value)
        }
      )
    } else {
      resolve(store.state[state])
    }
  })
}

const routes = [
  {
    path: '/',
    meta :{ title:process.env.VUE_APP_TITLE},
    beforeEnter: redirectIfNotAuth
  },

  {
    path: '/busquedas',
    name: 'Busquedas',
    component: VOID,
    beforeEnter: redirectIfNotAuth,
    children: [ 
      { 
        path: '', 
        name: 'Listado',
        beforeEnter: redirectIfNotAuth, 
        component: () => import(/* webpackChunkName: "Busquedas" */ '../components/Busquedas/Busquedas.vue'),
      },
      
      { 
        path: 'edit/:id', 
        name: 'Editar Busqueda',
        beforeEnter: redirectIfNotAuth, 
        component: () => import(/* webpackChunkName: "Busquedas" */ '../components/Busquedas/Busqueda.vue'),
      },
     
    
      { 
        path: 'new', 
        name: 'Nueva Busqueda',
        beforeEnter: redirectIfNotAuth, 
        component: () => import(/* webpackChunkName: "Busquedas" */ '../components/Busquedas/Busqueda.vue'),
      },
       
    ] 
  },


  {
    path: '/portales',
    name: 'Portales',
    component: VOID,
    beforeEnter: redirectIfNotAuth,
    children: [ 
      { 
        path: '/', 
        name: 'Listado',
        beforeEnter: redirectIfNotAuth, 
        component: () => import(/* webpackChunkName: "Portales" */ '../components/Portales/Portales.vue'),
      },
      { 
        path: 'edit/:id', 
        name: 'Editar Portal',
        beforeEnter: redirectIfNotAuth, 
        component: () => import(/* webpackChunkName: "Portales" */ '../components/Portales/Portal.vue'),
      },
      { 
        path: 'new', 
        name: 'Nuevo portal',
        beforeEnter: redirectIfNotAuth, 
        component: () => import(/* webpackChunkName: "Portales" */ '../components/Portales/Portal.vue'),
      }, 
    ] 
  },

]




const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: 'history',
  routes
})


router.beforeEach((to, from, next) => {

  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  if(nearestWithTitle) document.title = nearestWithTitle.meta.title;


  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create, so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
});

export default router
