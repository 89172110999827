const MyUtil = {
    install(Vue, options) {
       Vue.prototype.$util = {
            obj2param:(o) =>{
                return (typeof o === 'object' ? Object.entries(o).map((val) => `${val[0]}=${val[1]}`).join('&') : undefined)
            },
        }
    }
}
  
export default MyUtil;