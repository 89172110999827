import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';

//import '@babel/polyfill'

import 'roboto-fontface/css/roboto/roboto-fontface.css'

import '@mdi/font/css/materialdesignicons.css'
import 'font-awesome/css/font-awesome.css'

import {format} from 'date-fns'
import VueMask from 'v-mask'

import VueFileAgent from 'vue-file-agent'
import VueFileAgentStyles from 'vue-file-agent/dist/vue-file-agent.css'


import MyUtil from './plugins/util'


Array.prototype.chunk = function (chunk_size) {
  if ( !this.length ) {
      return [];
  }

  return [ this.slice( 0, chunk_size ) ].concat(this.slice(chunk_size).chunk(chunk_size));
};

Vue.use(MyUtil)

Vue.config.productionTip = false

Vue.filter('LEN', function (value) {
  return value.length
})

Vue.filter ('DDMMAAAA_HHMM', function (value) {
  if (!value) return ''
  return format(value ?new Date(value): Date.now(), 'dd-MM-yyyy HH:mm:ss.00')
})

Vue.filter ('DDMMAAAA', function (value) {
  if (!value) return ''
  return format(value ?new Date(value): Date.now(), 'dd-MM-yyyy')
})

Vue.filter ('AAAAMMDD', function (value) {
  if (!value) return ''
  return format(value ?new Date(value): Date.now(), 'yyyy-MM-dd')
})

Vue.filter('PADSTAR', (val,targetLength=4,padString='0') => {
  let str = typeof(val)=='number' ? val.toString() : val
  return str.padStart(targetLength,padString)
})

Vue.use(VueMask)
Vue.use(VueFileAgent)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')


