import Vue from 'vue'
import Vuex from 'vuex'

import Vuetify from '@/plugins/vuetify'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    log:false,

    root_api:process.env.VUE_APP_ROOT_API,

    snackbar:[],

    //productoCFG:"",


    _cfg:{
      dias:15,
      //dias_min:[],
      //dias_max:[],
    },

    login:false,
    _session:{
      login:false,
      toke:"",
      idUsr:"",
      tipoUsr:0,
      ComboBox:"v-select",
    },

    menuPpal:{
      drawer: false,
      title:{text:"", ico:"",class:""},
      items:[]
    },

    dataSite:{
      title:process.env.VUE_APP_TITLE,
      logo: false,
      class:"font-weight-medium title",
      link:""
    },

    alert:false,

    //quantityOrders:0



  },
  getters: {
  //computeds
    /*
    CFGPROD (state) {
      state.productoCFG=JSON.parse(process.env.VUE_APP_CFG_PRODUCTO)
      return state.productoCFG
    },
    */
    
    ROOT_API (state) {
      return state.root_api
    },

    CFG (state){
      return state["_cfg"]
    },

    SESSION (state) {
      return state["_session"]
    },

    ALERT (state) {
      return state.alert
    },


    
    dataSite (state){
      return state.dataSite
    },

    /* getSnackBar */
    getSnackBar: state => {
      
      let i=0

      state.snackbar.map ( (item, idx)=>{
        if (item.status) i++
        state.snackbar[idx].style=`top: ${(i-1)*55}px`
      })

      return state.snackbar
    }
    /* getSnackBar */



  },
  mutations: {
  //methods sincronos  
    setSession (state,val){
      let s=state["_session"]
      s.login=v.login
      s.toke=v.token,
      s.idUsr=v.isUsr,
      s.tipoUsr=v.tipoUsr
      s.ComboBox=b.ComboBox
    },

    logOut (state){
      localStorage.clear()
      state._session=undefined
    },

    setNotif (state,val){
      console.log('Vuetify',Vuetify)
      Vuetify.framework.goTo(0,{duration: 600,offset: 0, easing: 'easeInOutCubic'})
      state.snackbar.push(val)
    
    },

    //setQuantityOrders (state, val=0){
    //  state.quantityOrders=val
    //}


    //state._session=undefined
  },
  actions: {
    //methods asincronos
    async getQuantityOrders({state,commit,rootState}){
      //commit('setCountOrders',1)

      let opt={
        method: 'GET', 
        mode: 'cors'
      }
      let params=[
        {field:'p.estadoID',val:50}
      ]
      params=JSON.stringify(params)
      let response = await fetch(`${state.root_api}cms/pedidos?_filters=${params}`, opt);

      if (response.ok){
        //let r=await response.json()
        commit('setQuantityOrders',(await response.json()).length)
      }


      /*
      console.log("deleteACart")
      let {pedidoID=0} = state.header
      if (pedidoID==0) return false
      let opt={
        method: 'delete', 
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },  
        body: null
      }
  
      let response = await Fetch(`${rootState.root_api}pedidos/${pedidoID}`, opt)
      if (response && response.ok){
        let r = await response.json()
        commit('setHeader')
      }
      else {
        alert('Error: No se pudo anular el pedido!')
      } 
      console.log("deleteACart")
      */
    },
    
  },
  modules: {
  },
})
