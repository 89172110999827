<template>
  <v-app v-resize="onResize">
    <!-- INI HEADER -->
    <v-app-bar 

      v-if="viewHeaderFooter"
     
      app 
      flat
      :dense="SESSION.login"  
      color="primary" 
      :min-height="`${SESSION.login ? '48px':'64'}`" 
      max-height="48px" 
      
    >
      <div class="d-flex  d-xl-none" v-if="SESSION.login==true && SESSION.userType!=0">
        <v-btn 
          icon 
          class="mr-2 "  
          color="white"
          @click.stop="$store.state.menuPpal.drawer = !$store.state.menuPpal.drawer"
        >
          <v-icon>fa fa-navicon</v-icon>
        </v-btn>
      </div>
      <v-img
        v-if="dataSite.logo" 
        :alt="dataSite.title" 
        :title="dataSite.title"
        class="shrink ml-n4 my-1"
        contain
        src="/images/logo.jpg"
        transition="scale-transition"
        height="48"
      />
      <span v-else class="text-no-wrap  white--text" :class="[dataSite.class]">{{dataSite.title}}</span>
      <v-spacer />
      <template v-if="!SESSION.login">
        <v-form class="mx-0 px-0">
          <v-container class="mx-0 pl-3 pr-0">
            <v-row justify="end" >
              <v-col cols="4" >
                <v-text-field
                  v-model="loginUsr"
                  ref="loginUsr"
                  label="Usuario"
                  dark
                  filled
                  
                  dense
                  class="mt-5 caption"
                />
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="loginPass"
                  ref="loginPass"
                  label="Contraseña"
                  dark
                  filled
                  
                  dense
                  class="mt-5 caption"
                  type="password"
                  width="10"
                />
              </v-col>
              <v-col cols="auto" class="text-right justify-end">
                <v-btn  class="d-none d-lg-inline mt-6 body-2" text color="white"  @click="login()" :loading="loadingLogin">Ingresar</v-btn>
                <v-btn  class="d-inline d-lg-none mt-6" text color="white" @click="login()" :loading="loadingLogin">
                  <v-icon>fa fa-sign-in</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </template>
      <template v-else>
        <v-container class="text-right" >
          <v-btn class=" body-2 mx-2"  color="white" text x-small  @click="logout()">Salir</v-btn>
        </v-container>
      </template>
    </v-app-bar>
    <!-- FIN HEADER -->
    <!-- INI MENU -->
    <v-navigation-drawer 
      v-if="SESSION.userType!=0"
      tag="nav" 
      v-model="$store.state.menuPpal.drawer"
      :clipped="true" 
      :absolute="true" 
      :app="false" 
      :temporary="false"
      :mobile-breakpoint="1903"
      color="grey lighten-5" 
      hide-overlay
      class="pb-10"
      style="position:fixed"
      width="150px"
    >
      <v-list class="mt-0 pt-0">
        <v-list-item dense class="d-flex d-xl-none pa-0 ma-0" > 
          <v-spacer></v-spacer>
          <v-btn icon  @click.stop="$store.state.menuPpal.drawer = !$store.state.menuPpal.drawer" class="mr-1">
            <v-icon size="12">fa fa-close</v-icon>
          </v-btn>
        </v-list-item>
        <v-list>
          <v-list-item-group class="mt-n4 mt-lg-n2"  v-model="navSelected">
            <template v-for="(item, i) in navItems">
              <v-list-group v-if="item.subItems && item.subItems.length>0" :key="i"  @click="router_link(item.url)" >
                <template v-slot:activator>
                  <v-list-item-title v-text="item.title" class="caption" /> 
                </template>
                  <v-card class="px-0 py-1 ma-0 card--active" tile elevation=1 color="primary dark-2" dark>
                    <v-list-item v-for="(subItem, j) in item.subItems" :key="j" @click="router_link(subItem.url)" class="pr-0">
                      <v-list-item-icon v-if="subItem.preIco" class="preIco">
                        <v-icon  v-text="subItem.preIco" />
                      </v-list-item-icon> 
                      
                      <v-list-item-title v-text="subItem.title" class="caption" />
                      
                      <v-list-item-icon v-if="subItem.posIco">
                        <v-icon v-text="subItem.posIco" />
                      </v-list-item-icon>
                    </v-list-item>
                  </v-card>
              </v-list-group>

              <v-list-item v-else :key="i" @click="router_link(item.url)" class="pr-0 linkPpal"> 
                <v-list-item-icon v-if="item.preIco">
                  <v-icon  v-text="item.preIco"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.title" class="caption"></v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon v-if="item.posIco">
                  <v-icon v-text="item.posIco"></v-icon>
                </v-list-item-icon>
              </v-list-item>
            </template>
          </v-list-item-group>
        </v-list>
      </v-list>
    </v-navigation-drawer>
    <!-- FIN MENU -->
    <v-main :class="viewHeaderFooter?'':'notLogin'">
      <!-- INI ALERT -->
      <v-alert 
        v-model="ALERT"
        type="error"
        dense
        class="mt-3  mt-lg-1 mr-1 alert-right"
        max-width="300"
      >
        Usuario/Contraseña inválido.
      </v-alert>
      <!-- FIN ALERT -->
      <v-container fluid class="py-2" v-if="SESSION.login">
        <v-row >
          <v-col cols=12 class="pt-2" >
            <v-breadcrumbs :items="ruta" class="ma-0 px-0 py-1 caption">
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :disabled="item.disabled"
                  @click.stop="router_link(item.path)"
                >
                  {{ item.text }}
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>
            <v-divider class="ma-0" v-if="Length(ruta)"/>
          </v-col>
          <v-col cols=12 class="pt-0 h100">
            <router-view class="mx-0 mt-n3"></router-view>
          </v-col>
        </v-row> 
      </v-container>
      <template v-for="(item, index) of getSnackBar" > 
        <transition 
            enter-active-class="animated bounceInRight"
            leave-active-class="animated bounceOutRight"
          :key="`animated${index}`">
        <v-snackbar
          v-if="item.status"
          v-model="item.status"
          absolute
          top
          right
          :key="index"
          style="transition: top 1s;"
          :style="item.style"
          :type="item.type"
          :color="item.color"
          :class="item.class"
        >
          {{item.text}}
        </v-snackbar>
        </transition>
      </template> 
    </v-main>
    <v-footer v-if="viewHeaderFooter" app color="primary" class="caption font-weight-light" dark style='z-index:10'>
        <v-row class="py-0">
          <v-col cols=12 md=9 class="py-3">
            <span class="text-truncate">v{{version}} | Todos los derechos reservados.</span><b>{{dataSite.title}}</b>® 2022
          </v-col>
          <v-col cols=12 md=3  class="py-3 text-lg-right text-left "> 
            Desarrollado por <span title="sebastianphi@gmail.com" class="px-2">S. Magnin</span>
          </v-col>
        </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import {mapGetters} from 'vuex'
import * as dataVersion from '../package.json';

export default {
  name: 'App',
   components: {
  },
  async created  () {
    console.log( dataVersion)
    this.version = dataVersion.version

  },
  mounted () {


    this.onResize()
   
    if (this.$refs.loginUsr) {
      this.$refs.loginUsr.$refs.input.focus()
      this.$refs.loginUsr.$refs.input.select()
    }
     

    this.$store.state.menuPpal.drawer=false

    this.loadNav()

    

   // if (this.$store.state._session.login!=true) this.drawer=false

  },

  watch: {
    navSelected(val) {
    }

  },
  computed:{
    ...mapGetters(['SESSION','ROOT_API','ALERT','dataSite','getSnackBar']),

    viewHeaderFooter: function (){
      let {login,userType} = this.SESSION
      if (login==true) {
        if (userType==0) {
          return false
        }
      }
      return true
      
    },

    ruta : function () {
      let route=this.$route
      let breadcrumbs=[]
      
      if (route.fullPath=="/" || route.path=="") return breadcrumbs
      breadcrumbs=route.matched.map( (item, idx, arr) => {
        let b = arr.length - 1 == idx  || item.components.default.name=='void'
        return {
          text:item.name,
          link: !b,
          path:item.path,
          disabled: b,
        }
      })
      return breadcrumbs
    }
    
  },

  methods:{
      Length (arr){

        if (typeof arr==='object') return arr.length
        return 0
        
      },

      router_link(path) {
        this.$router.replace(path).catch((err)=>{})
        
        if (!this.$route.matched.length){  
          this.$router.replace("/").catch((err)=>{})
        }
        localStorage.setItem('route', path)
      },

      onResize () {
        this.windowSize = { x: window.innerWidth, y: window.innerHeight }

        if (!this.$store.state._session.login) {
          this.$store.state.menuPpal.drawer=false
        } else {
          if (window.innerWidth>1904) {
            this.$store.state.menuPpal.drawer=true
          } else {
            if (this.windowSize.x!=window.innerWidth) this.$store.state.menuPpal.drawer=false
          }
        } 
      },

      async loadNav (){
            /*
            INI CARGA MENU
            */

           if (this.$store.state['_session'].userType==0) return

            let opt={
              method: 'GET', 
              mode: 'cors'
            }
            let resMenu = await fetch(`${this.ROOT_API}cms/navitems/menu`, opt)
            if (resMenu.ok){
              
              this.navItems = await resMenu.json()
    
            }
            else {
            }
            /* 
            FIN CARGA MENU
            */

      },

      async login () {
/* INI */
        try {
          let opt={
            method: 'GET', 
            mode: 'cors'
          }
          let state=undefined
          this.loadingLogin=true

          let res = await fetch(`${this.ROOT_API}cms/usuarios/authUsr?usr=${this.loginUsr}&pass=${this.loginPass}`, opt)
          if (res.ok) {
            let data = await res.json()

            this.$store.state._session=data
            
            localStorage.setItem('token', data.token)
            
            if (window.innerWidth>1264) {this.$store.state.menuPpal.drawer=true}
                
            this.loginUsr="" 
            this.loginPass=""

            this.$router.push(data.redir).catch((err)=>{})
          }
          else {
            this.$store.state._session.login=false
            this.$store.state.menuPpal.drawer=false

            this.$store.state.alert=true
            setTimeout(e=> {
              this.$store.state.alert=false 
              this.$refs.loginUsr.$refs.input.focus()
            }, 1500)
          }
        }
        catch (err) {
          this.$store.state._session.login=false
          this.$store.state.menuPpal.drawer=false
        }
        this.loadingLogin=false
       
/* FIN */

      },

      logout(){
        localStorage.clear()

        this.$store.state._session.login=false
        this.$store.state.menuPpal.drawer=false
        
        setTimeout(e=>this.$refs.loginUsr.$refs.input.focus() , 500)
      },

      validaUsr () {

      }
    
  },

  data () {
    return {

      version:null,

      windowSize: {x: 0, y: 0,},

      /* */
      loadingLogin:false,
      /* */

      /* INI NAV BAR*/
      navSelected:null,
      navItems:[],
      /* FIN NAV BAR*/

      /* INI LOGIN */
      loginUsr:"",
      loginPass:"",
      /* FIN LOGIN*/
    }
  },
};
</script>


<style lang="scss" >
//Paginacion
.v-pagination__navigation,
.v-pagination__item
{
  height: 24px!important;
  width: 24px!important;
  //min-width:24px!important;

  font-size: 12px!important;
}
//Paginacion

.theme--light.v-data-table.v-data-table--fixed-header thead th,
.theme--light.v-data-table .v-data-table-header th.sortable.active,
.v-data-table-header__icon,
.theme--light.v-data-table .v-data-table-header th.sortable:hover
 {
  background-color: var(--v-primary-base)!important;
  color: white !important;
}

.v-icon.v-data-table-header__icon{
  margin-left: 5px !important;

}

.theme--light.v-data-table thead th {
  border:var(--v-primary-base) solid 1px !important;

  font-size: 14px !important;
  font-weight:normal !important;


}
.v-application--is-ltr .v-data-table th {
    text-align: none!important;
}

.v-text-field__prefix {
  padding-top: 0px!important;
  height: 20px !important;
}

.hints__d_none.v-text-field__details {
  display:none;
}

.v-breadcrumbs__item--disabled {
  text-transform: uppercase !important;
}

a.v-breadcrumbs__item {
  color: black !important;
}

a.v-breadcrumbs__item--disabled {
  color: gray !important;
}




.card--active{
  position:relative;
  &::before {
    content: '';
    display: block;
    background-color:var(--v-primary-base);// #5c5f69;
    width: 11px;
    height: 11px;
    opacity: 1;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    //transition: .2s all;
    position: absolute;
    top:-5px;
    //bottom: -36px;
    left: 32px;
    //  z-index: 999 !important;
  }
}

  .preIco.v-list-item__icon {
    margin-right: 8px !important;
  }

  .v-icon.v-icon {
    //font-size:14px!important
  }

  .v-icon.headIcon.v-icon {
    font-size:18px!important
  }

  .v-navigation-drawer  {
    top:0px!important;
    height: 100%!important; 
    //position:relative;
    z-index: 10!important;
  }



  @media (min-width: 1903px) {
    main {
      padding-left: 145px!important;    
    }

    .v-navigation-drawer  {
      top:48px!important;
      max-height: calc(100% - 48px - 28px)!important; 
    }

    main.notLogin {
      padding-left: 0!important;
    }
  }


  .v-menu__content {
    border-radius: 0!important;
  }

  .v-dialog {
    margin: 12px!important;
  }

  .alert-right {
    float:right
  }

  .v-text-field__details {
    margin-bottom: 0px!important;
  }







//  .v-list-item--active.v-list-item--link.theme--light
//  {
//    background-color: green;
//  }

//  .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
//      color:green!important;
//  }
/*
.v-list-item.v-list-item--link.theme--light,
.v-list-group__header.v-list-item.v-list-item--link.theme--light
{
  position: relative;
  
  &::before{
      display:block;
      content: '';
      height: 100%;
      width: 4px;
      background-color: var(--v-primary-lighten2);
      transform: scaleY(1)!important;
      opacity: 0!important; 
  }
  &:hover:before { 
      transform: scaleY(1)!important;  
      opacity: 1!important; 
    // transition: transform 250ms ease !important;
  }

}
*/

</style>
