<template>
    <v-container class="px-0">
      CMS
      <router-view></router-view> 
    </v-container>
   
</template>
<script>
  export default {
    name: 'CMS',

    components: {
      
    },

    data: () => ({

    }),
    created: function () {
    },
    mounted: function () {
    },
    methods: {
    },
    computed: {
      
    },
  }
</script>
<style scoped>
</style>
